import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { content } from "./content";
import { AnimateSharedLayout,  } from "framer-motion";
import React, { useState } from "react";

import {Bounce, Zoom} from 'react-reveal'; // Importing Zoom effect
import { Button } from "react-bootstrap";

const transition = { duration: 0.5, ease: "easeInOut" };



const postVariants = {
  initial: { y: 100, opacity: 0 },
  enter: { y: 0, opacity: 1, transition },
  exit: { y: -100, opacity: 0, transition }
};

export const Post = ({ match }) => {
  
  const id = Number(match.params.id);

  const { title, description, text, imgSrc } = content[id];

  return (
    <motion.div
      className="page"
      initial="exit"
      animate="enter"
      exit="exit"
      variants={postVariants}
    >

      <div className="post">
        <img className="post__img" src={imgSrc} alt={title} />
        <h1 className="heading">{title}</h1>
        <p>{description}</p>
        <p>{text}</p>
      </div>

    </motion.div>
    
  );
};





const blogVariants = {
  enter: { transition: { staggerChildren: 0.1 } },
  exit: { transition: { staggerChildren: 0.1 } }
};

export const Blog = () => {
  return (
    
      <div >

        <div className="page1" id="home">
        <Zoom>
            <h1>Luukas Anttila</h1>
            <Zoom delay={1000}>
            <h2>Web/Mobile Developer</h2>
            </Zoom>
            <Zoom delay={2000}>
            <h2>BBA Student</h2>
            <Zoom delay={3000}>
            <div className="row1" />
            </Zoom>
            </Zoom>
            </Zoom>

        </div>  
        <div className="pageAbout" id="about">
        <Zoom >
       
        <img className="profileimg" src={"profileround1.png"} alt="asd" />

            <h1>About Me</h1>
            

            <p>I’m a third year Business Information Technology student at Tampere University of Applied Sciences.</p>
            <p>
            My main focus is in JavaScript front-end web and mobile development. I have also gained experience in full-stack development from various projects.
            </p>  
            <p>
            I'm passionate about modern web- and mobile-development frameworks and creating cutting-edge applications with them.
            </p>
            <p>
            My greatest motivation is curiosity and personal development.
            
            </p>
            <Button href="https://drive.google.com/file/d/1HuUU1w8NxEuNvV35IBUDZpYYwKdp3DuY/view"
                target="_blank">Resume</Button>
                         <Zoom >
            <div className="row1" />
            </Zoom>
            
            </Zoom>
   
        </div>  
        <div className="page" id="projects" >
        <Zoom >
            <h1>Projects</h1>
            </Zoom>
            


<Zoom>
      <div className="post">
        <img className="post__img" src="eramiesiso.png" alt="erämiehen uniikki"/>
        <h1 className="heading">Erämiehen Uniikki</h1>
        <p>
        <p>
        Website for a small business that produces and sells unique leather products.
        </p>
        <p>
          This was a 3 person team project, with one developer designing the front-end prototype in Figma and implementing it with me.
          The third developer assisted me in backend development. 
          <p>
          We also created E-Commerce features, such as registeration and shopping cart,
           but those were left out in the final product, since the customer decided to go with a showcase site. Currently it is up to them when the site is launched.
           </p>
        </p>
        <li>
          Frontend: React.js
          </li>
          <li>
          Backend: Node.js
          </li>
          <li>
          Database: MySQL
          </li>

          </p>
          <Button href="https://eramiesproto.herokuapp.com/"
                target="_blank">Test Site</Button>

      </div>
      
      </Zoom>
      
      <Zoom>
      <div className="row1" />

      <div className="post">
        <img className="post__img" src="nasdaqiso.png" alt="nasdaqiso.png"/>
        <h1 className="heading">Nasdaq Stock App</h1>
        <p>
        <p>
        Personal project that analyses stock historical data through Nasdaq’s own API.
        </p>
        <p>
         The user enters any Nasdaq stock marker and the time frame of the data search, and the application will calculate the Bullish Trend,
          Simple moving and Trading volumes and price changes. 
          </p>
          <li>
          Frontend: React.js
          </li>
          <li>
          Backend: Node.js
          </li>
          </p>
        
        <Button href="http://nasdaq-stock-app.herokuapp.com/"
                target="_blank">Live Site</Button>

      </div>
      </Zoom>
      <Zoom>
      <div className="row1" />
      <div className="post">
        <img className="post__img" src="barsiso.png" alt="barsiso.png"/>
        <h1 className="heading">Bars Tampere</h1>
        <p>
        <p>
        Personal project. Android application, that displays the current location, location of bars and their opening hours around Tampere.
        </p>
        <p>
          Uses Mapbox SDK to display the map and fetches data from backend to create markers on the map.
           Opening hours of each bar is displayed in a pop-up when clicking the respective marker.
        </p>
        <li>
          Frontend: Native Android
          </li>
          <li>
          Backend: Node.js
          </li>
          

        </p>
        <Button href="https://play.google.com/store/apps/details?id=fi.tuni.barstampere"
                target="_blank">Play Store</Button>

      </div>
      </Zoom>
      <Zoom>
      <div className="row1" />

      <div className="post">
        <img className="post__img" src="pymiso.png" alt="pymiso.png"/>
        <h1 className="heading">Pientuottajat yhteistyössä markkinoille</h1>
        <p>
        Food chain development project in co-operation with Ahlman, in which we designed a business 
        logic and the UI for a future application.
        </p>
        <p>
          We worked in a team closely with the customer group of lamb farmers to design a new food chain platform, based on the customers needs and feedback. 
        </p>
        

      </div>
      <div className="row1" />
      </Zoom>




        </div>




    </div>
  );
};