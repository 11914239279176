
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AnimatePresence,  } from "framer-motion";

import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import "./styles.css";

import { Blog, Post } from "./Blog";
import NavBar1 from "./NavBar1"
import Canvas from "./Particles"
import Footer from "./Footer"

export default function App() {
    

  return (
    <Router>
              <Canvas/>

       <NavBar1/>
      <Route
        render={({ location }) => (
          <AnimatePresence exitBeforeEnter initial={false}>
            <Switch location={location} key={location.pathname}>
              <Route exact path="/" component={Blog} />

            </Switch>
          </AnimatePresence>

        )}
      />
                    <Footer/>

    </Router>
    
  );
}
